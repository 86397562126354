<template>
  <div></div>
</template>
<script>
export default {
  created() {
    localStorage.removeItem("token");
    localStorage.removeItem("isLoginLinkedin");
    this.$router.push({ name: "Login" });
  },
};
</script>
