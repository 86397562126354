module.exports.loading = {
  state: () => ({
    isLoading: false,
  }),
  getters: {
    isLoading: (state) => {
      return state.isLoading;
    },
  },
  mutations: {
    ["SET_LOADING"](state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    setLoading({ commit }, isLoading) {
      commit("SET_LOADING", isLoading);
    },
    clearLoading({ commit }) {
      commit("SET_LOADING", false);
    },
  },
};
