<template>
  <!-- View Layout -->
  <v-row
    class="d-flex flex-row justify-space-between align-start ma-0 pa-0"
    align="stretch"
  >
    <!-- Snackbar for small info alerts -->
    <v-snackbar
      @input="(v) => (showSnackbar = v)"
      :value="showSnackbar"
      timeout="3000"
      :vertical="true"
      app
      top
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="24"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-col cols="12" class="pa-0 d-flex">
      <v-sheet
        color="white"
        elevation="3"
        rounded
        class="mr-md-1 pb-3 mx-0 column-content w-100"
        style="min-height: 90vh"
      >
        <v-row class="px-2 mt-6 pb-0 justify-space-between" no-gutters>
          <v-col
            cols="12"
            md="12"
            class="mr-0 pr-0 py-0 d-flex flex-column align-baseline justify-start"
          >
            <v-sheet
              color="bsprimary2"
              elevation="3"
              rounded
              class="pa-4 mb-2"
              width="100%"
              height="100%"
            >
              <div
                class="w-100 pt-0 d-flex flex-row align-baseline justify-center"
              >
                <h5 class="mb-4 text-left">Enter Search Data</h5>
              </div>
              <v-form @submit.prevent="submitForm" ref="form">
                <div class="d-flex justify-space-between align-center">
                  <!-- Name Input -->
                  <v-text-field
                    v-model="form.name"
                    label="Search Name"
                    class="small-font lc-input-fields"
                    dense
                    solo
                  >
                    <v-tooltip color="bssecondary2" bottom slot="append">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="bssecondary2"
                          dense
                          small
                          v-bind="attrs"
                          v-on="on"
                          >mdi-information-variant</v-icon
                        >
                      </template>
                      <span v-html="'Give this search a name!'"></span>
                    </v-tooltip>
                  </v-text-field>

                  <!-- URL Input -->
                  <v-text-field
                    v-model="form.url"
                    label="Search URL"
                    class="small-font lc-input-fields"
                    :rules="urlRules"
                    dense
                    solo
                  >
                    <v-tooltip color="bssecondary2" bottom slot="append">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="bssecondary2"
                          dense
                          small
                          v-bind="attrs"
                          v-on="on"
                          >mdi-information-variant</v-icon
                        >
                      </template>
                      <span
                        v-html="
                          'Add Linkedin Sales Navigator search people url!'
                        "
                      ></span>
                    </v-tooltip>
                  </v-text-field>
                </div>

                <!-- Conditional Display for File or Text Input -->
                <!-- <div v-if="form.jobDescriptionType === 'File Input'">
                  <v-file-input
                    v-model="form.file"
                    label="Job File Input"
                    :rules="fileRules"
                    accept=".pdf,.docx"
                    class="small-font lc-input-fields"
                    dense
                    solo
                  >
                    <v-tooltip color="bssecondary2" bottom slot="append">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="bssecondary2"
                          dense
                          small
                          v-bind="attrs"
                          v-on="on"
                          >mdi-information-variant</v-icon
                        >
                      </template>
                      <span v-html="'Add job description file!'"></span>
                    </v-tooltip>
                  </v-file-input>
                </div> -->

                <div class="px-1">
                  <v-textarea
                    v-model="form.jobDescriptionText"
                    label="Job Field Text Area"
                    :rules="textRules"
                    class="small-font lc-input-fields"
                    dense
                    solo
                  ></v-textarea>
                </div>

                <!-- Submit Button -->
                <v-btn
                  :disabled="isSearchLoading"
                  type="submit"
                  class="my-1 ml-4"
                  color="white"
                  small
                  elevation="2"
                  style="text-transform: capitalize"
                >
                  <v-progress-circular
                    v-if="isSearchLoading"
                    indeterminate
                    :size="18"
                    :width="2"
                    color="blue-grey"
                    class="mr-2"
                  ></v-progress-circular>
                  <v-icon v-if="!isSearchLoading" class="mr-1" small
                    >mdi-check</v-icon
                  >
                  <span v-if="!isSearchLoading">Submit</span>
                  <span v-else>Processing...</span>
                </v-btn>

                <div v-if="validationError" class="error-message">
                  {{ validationError }}
                </div>
              </v-form>
            </v-sheet>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="mr-0 pr-0 py-0 d-flex flex-column align-center justify-start"
          >
            <v-sheet
              color="bsprimary2"
              elevation="3"
              rounded
              class="pa-4 mb-2"
              width="100%"
              height="100%"
            >
              <div
                class="w-100 pt-0 d-flex flex-row align-baseline justify-center"
              >
                <h5 class="mb-4 text-left">Include / exclude criteria</h5>
              </div>
              <v-form ref="criteriaForm">
                <v-row>
                  <v-col
                    v-for="(item, index) in criteriaSwitches"
                    :key="index"
                    cols="12"
                    sm="6"
                    md="3"
                    lg="2"
                  >
                    <div class="d-flex ga-6 flex-wrap align-center">
                      <v-switch
                        v-model="item.value"
                        class="ml-4"
                        color="bssecondary2"
                      ></v-switch>
                      <span class="v-label">{{ item.label }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-sheet>
          </v-col>
          <v-col cols="12" md="12" class="mr-0 pr-0 py-0 settings-div">
            <v-sheet
              color="white"
              elevation="3"
              rounded
              class="d-flex align-start justify-center mt-2"
            >
              <v-data-table
                v-model:expanded="expanded"
                :headers="headers"
                :items="filteredItems"
                item-key="id"
                dense
                show-expand
                class="px-3 py-4 fill-height w-100"
                :footer-props="{
                  'items-per-page-options': [20, 30, 50, 100, 250],
                }"
              >
                <template v-slot:top>
                  <v-row
                    class="px-4 py-1 align-baseline"
                    no-gutters
                    justify="space-between"
                  >
                    <!-- Heading and Switch in One Column -->
                    <v-col
                      cols="12"
                      class="d-flex align-center justify-space-between mx-0 px-0 py-0"
                    >
                      <h4 class="my-2 mx-0 text-left pr-2">
                        <span>Scoring Results</span>
                      </h4>
                      <div
                        class="d-flex ga-6 flex-wrap align-center"
                        v-if="eliminatedProfiles.length > 0"
                      >
                        <v-switch
                          v-model="isEliminatedToggle"
                          color="bssecondary2"
                        ></v-switch>
                        <span class="v-label">Include rejected profiles</span>
                      </div>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:item.profile_url="{ item }">
                  <a :href="item.profile_url" target="_blank">Profile</a>
                </template>

                <template v-slot:item.total_score="{ item }">
                  {{ ` ${item.total_score} / ${item.outOfTotal}` }}
                </template>

                <template v-slot:item.score_percentage="{ item }">
                  {{ item.score_percentage }}%
                </template>

                <template v-slot:item.accepted="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        {{ item.accepted ? "No" : "Yes" }}
                        <!-- Display the score -->
                      </div>
                    </template>
                    <span>{{ item.acceptedRemarks }}</span>
                    <!-- Tooltip text containing the remarks -->
                  </v-tooltip>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-card flat class="my-2">
                      <v-card-text>
                        <score-details
                          :data="item.scoring"
                          :criteriaSwitches="criteriaSwitches"
                        ></score-details>
                      </v-card-text>
                    </v-card>
                  </td>
                </template>

                <template v-slot:no-data>
                  {{
                    isSearchLoading
                      ? "Loading data..."
                      : filterScoringData.length === 0 &&
                        eliminatedProfiles.length > 0
                      ? "No profiles meet the criteria"
                      : "No records found"
                  }}
                </template>
              </v-data-table>
            </v-sheet>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import ScoreDetails from "./ScoreDetails.vue";
import axios from "axios";
import config from "../../../../config/app.json";

export default {
  components: {
    ScoreDetails,
  },
  props: ["id"],
  async created() {
    if (this.id) {
      const history = this.historyData.find((f) => f.id === this.id);
      this.form.url = history.searchUrl;
      this.form.name = history.searchName;
      if (history.jobDescriptionText) {
        this.form.jobDescriptionText = history.jobDescriptionText;
        this.form.jobDescriptionType = "Text Area";
      }
      await this.scoringProfilesWithJobDescId(history.job_description_id);
    }
  },
  data() {
    return {
      form: {
        url: "",
        file: null,
        name: "",
        jobDescriptionType: "Text Area", // 'File' or 'Text'
        jobDescriptionText: "",
      },
      urlRules: [
        (v) => !!v || "URL is required",
        (v) => /https?:\/\/.+/.test(v) || "Must be a valid URL",
      ],
      fileRules: [
        (v) => !!v || "File is required",
        (v) =>
          !v ||
          (v &&
            [
              "application/pdf",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ].includes(v.type)) ||
          "Only PDF and DOCX files are allowed",
      ],
      textRules: [(v) => !!v || "Job description cannot be empty"],

      validationError: "",
      expanded: [],
      headers: [
        { text: "Name", value: "name", align: "start", sortable: false },
        { text: "Job Title", value: "jobTitle" },
        { text: "Years of Experince", value: "yearsOfExperience" },
        { text: "Total Score", value: "total_score" },
        { text: "Percentage", value: "score_percentage" },
        { text: "Rejected", value: "accepted" },
        { text: "Link", value: "profile_url" },
        { text: "", value: "data-table-expand" },
      ],

      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",
      scoringData: [],
      filterScoringData: [],
      eliminatedProfiles: [],
      isEliminatedToggle: false,

      // Initialize all switches to true
      criteriaSwitches: [
        { label: "Job Title", value: true, key: "jobTitle" },
        // { label: "Job Type", value: true, key: "jobType" },
        {
          label: "Relevant Experience",
          value: true,
          key: "relevantExperience",
        },
        { label: "Years of Experience", value: true, key: "overallExperience" },
        { label: "Tenure", value: true, key: "tenure" },
        { label: "Location", value: true, key: "location" },
        {
          label: "Company Repuation",
          value: true,
          key: "companyReputation",
        },
        {
          label: "Company Size",
          value: true,
          key: "companySize",
        },
        { label: "Education", value: true, key: "education" },
        {
          label: "Technical Skills ",
          value: true,
          key: "technicalSkills",
        },
        // {
        //   label: "Soft Skills ",
        //   value: true,
        //   key: "softSkills",
        // },
        {
          label: "Keywords",
          value: true,
          key: "keywords",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      isSearchLoading: (state) => state.scorings.isSearchLoading,
      getUser: (state) => state.users.user,
      historyData: (state) => state.scorings.historyData,
      searchResults: (state) => state.scorings.searchResults,
      searchError: (state) => state.scorings.searchError,
      filteredItems() {
        return this.isEliminatedToggle
          ? [...this.filterScoringData, ...this.eliminatedProfiles]
          : this.filterScoringData;
      },
    }),
  },

  watch: {
    criteriaSwitches: {
      handler(newVal) {
        const data = this.FilterScoringData(
          this.scoringData.filter((f) => f.accepted),
          newVal
        );
        const eliminatedData = this.FilterScoringData(
          this.scoringData.filter((f) => !f.accepted),
          newVal
        );

        this.setFilterScoringData(data);
        this.setEliminatedData(eliminatedData);
      },
      deep: true,
    },

    searchResults(newResults) {
      this.scoringData = newResults;
      this.setFilterScoringData(
        this.FilterScoringData(
          newResults.filter((f) => f.accepted),
          this.criteriaSwitches
        )
      );
      this.setEliminatedData(
        this.FilterScoringData(
          newResults.filter((f) => !f.accepted),
          this.criteriaSwitches
        )
      );
      this.snackbarShow(true, "Scoring Results Successfully");
    },

    searchError(err) {
      if (err.response.status === 400) {
        this.validationError = err.response.data?.message;
      }

      if (err.response.status == 429) {
        this.snackbarShow(true, "Error", err.response.data?.message || "");
      } else if (err.response.status == 401) {
        this.snackbarShow(
          true,
          "Error",
          "Please Login your linkedin sales navigator account first"
        );
      } else {
        this.snackbarShow(
          true,
          "Error",
          err.message
            ? err.message
            : "Could not Search Profiles, try refreshing the page and starting again"
        );
      }
    },
  },
  mounted() {
    this.setSearchError(null);
  },
  methods: {
    ...mapActions({
      submitSearch: "submitSearch",
      setSearchError: "setSearchError",
    }),

    setScoringData(data) {
      this.scoringData = data;
    },

    setFilterScoringData(data) {
      this.filterScoringData = data;
    },
    setEliminatedData(data) {
      this.eliminatedProfiles = data;
    },

    // Scoring Section Filter
    FilterScoringData(defaultData, filterArray) {
      if (!defaultData || defaultData.length === 0) return [];

      if (!filterArray || filterArray.length === 0) return defaultData;

      const removeKeys = [
        "softSkills",
        "engagement",
        "otherRequirements",
        "jobType",
      ];

      const keysToDisable = new Set(
        filterArray.filter((f) => !f.value).map((f) => f.key)
      );

      return defaultData.map((item) => {
        let total_score = 0;
        let outOfTotal = 0;

        console.log("before New Scoring", item.scoring);
        const newScoring = Object.entries(item.scoring).reduce(
          (acc, [key, category]) => {
            if (!keysToDisable.has(key) && !removeKeys.includes(key)) {
              acc[key] = category;
              if (typeof category === "object") {
                const keysArr = Object.keys(category).filter(
                  (f) => !f.includes("remarks")
                );
                outOfTotal += keysArr.length * 10;
                total_score += keysArr.reduce(
                  (sum, key) => sum + category[key],
                  0
                );
              } else {
                outOfTotal += 10;
                total_score += category;
              }
            }
            return acc;
          },
          {}
        );

        return {
          ...item,
          scoring: newScoring,
          outOfTotal,
          total_score: total_score.toFixed(2),
          score_percentage: ((total_score / outOfTotal) * 100 || 0).toFixed(2),
        };
      });
    },

    async submitForm() {
      // Validate the form
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.validationError = "Please fix the errors above before submitting.";
        return;
      }

      if (
        this.form.jobDescriptionType === "Text" &&
        !this.form.jobDescriptionText
      ) {
        this.validationError = "Job description cannot be empty.";
        return;
      }

      this.validationError = "";

      // Prepare the form data for submission
      // this.setLoading(true);
      const formData = new FormData();
      formData.append("searchURL", this.form.url);
      formData.append("user_id", this.$store.getters.getUser.id);

      if (this.form.jobDescriptionType === "File Input") {
        formData.append("file", this.form.file);
      } else if (this.form.jobDescriptionType === "Text Area") {
        formData.append("jobDescriptionText", this.form.jobDescriptionText);
      }

      formData.append("searchName", this.form.name || "No Name");

      try {
        this.snackbarShow(
          true,
          "This may take a while depending on the amount of data. You will get an email once the search had been processed"
        );
        this.scoringData = [];
        this.filterScoringData = [];
        this.eliminatedProfiles = [];
        await this.submitSearch(formData);
      } catch (error) {
        console.error("Error:", error.response);
      }
    },

    async scoringProfilesWithJobDescId(desc_id) {
      try {
        const response = await axios.get(
          `${config.API_URL}/api/linkedin/scoringprofiles`,
          {
            params: { job_description_id: desc_id },
          }
        );

        const jobDesp = await axios.get(
          `${config.API_URL}/api/linkedin/getJobDescriptionById`,
          {
            params: { Id: desc_id },
          }
        );
        const desp = jobDesp.data.response;
        console.log("jobDesp", jobDesp);

        const data = response.data.response;
        if (data && data.length > 0) {
          const realData = data.map((m) => ({
            ...m,
            jobTitle: desp.jobTitle,
            yearsOfExperience: desp.yearsOfExperience,
            scoring:
              typeof m.scoring == "string" ? JSON.parse(m.scoring) : m.scoring,
          }));
          this.setScoringData(realData);

          this.setFilterScoringData(
            this.FilterScoringData(
              realData.filter((f) => f.accepted),
              this.criteriaSwitches
            )
          );
          this.setEliminatedData(
            this.FilterScoringData(
              realData.filter((f) => !f.accepted),
              this.criteriaSwitches
            )
          );

          this.snackbarShow(true, "Getting Scoring Results Successfully");
        }
      } catch (error) {
        console.error("Error:", error);
        this.snackbarShow(
          true,
          "Error",
          error.message
            ? error.message
            : "Could not find the history of scoring profiles"
        );
      }
    },
    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Helvetica Neue", "Ubuntu" !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
  color: black;
}
::v-deep .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}
.v-data-table {
  border: 1px solid #4b414124;
}
::v-deep th.text-start {
  min-width: 100px;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.settings-div .v-btn:not(.icon-only) {
  text-transform: initial;
  width: 146px;
  float: right;
}
.settings-div .v-btn .v-icon {
  width: 20px;
}
/* ::v-deep .v-input--selection-controls__ripple {

    margin: 0px !important;
} */
::v-deep .v-input__slider label {
  width: 75px;
}
.datatable-height {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.w-45 {
  width: 45% !important;
}
.h-100 {
  height: 100%;
}
.h-80 {
  height: 80%;
}
.highlighted {
  background-color: yellow;
}
.result_div {
  line-height: 1.5em;
}
::v-deep .result_div span {
  line-height: 1.5em;
  background-color: yellow !important;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 12px;
  line-height: 1.75rem;
  width: 100%;
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.result_alert {
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.sub-heading {
  font-size: 14px;
  font-weight: 500;
}

::v-deep .v-list-item__title {
  text-transform: capitalize;
}

::v-deep .v-select.v-input--dense .v-chip {
  margin: 1px 4px;
}

/* scroll styling */
#content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(77, 75, 75, 0.3);
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
}

/* backgrounds */
::v-deep .v-data-table {
  background-color: var(--v-bsprimary2-base) !important;
}
::v-deep .v-data-table th {
  background-color: var(--v-bsprimary1-base) !important;
}

.column-content {
  flex: 1;
  align-self: stretch;
}

.v-tooltip__content {
  max-width: 500px;
  white-space: normal;
  word-wrap: break-word;
}

::v-deep .success-btns {
  background-color: var(--v-bd1-base) !important;
}
::v-deep .danger-btns {
  background-color: var(--v-bd2-base) !important;
}

.tooltip-text {
  color: black;
}
.chatbot-settings-wrapper .v-btn {
  text-transform: capitalize;
  min-width: 200px;
  float: right;
}

.tabs-main {
  border-radius: 3px;
  margin-bottom: 5px;
}
.v-tabs .v-tab {
  color: #ffffff !important; /* Navy color */
}
.error-message {
  color: red;
  margin-top: 10px;
}
.submit-button {
  margin-top: 20px;
}

.login-button.success {
  background-color: green;
  color: white;
}
</style>
