import Vue from "vue";
import Vuex from "vuex";

// importing modules
const { users } = require("./users");
const { appbar } = require("./appbar");
const { alerts } = require("./alerts");
const { loading } = require("./loading");
const { scorings } = require("./scorings");

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    users,
    appbar,
    alerts,
    loading,
    scorings,
  },
});
